import React, { useContext, useEffect, useState } from 'react'
import { Menu as UikitMenu, ConnectorId } from '@pancakeswap-libs/uikit'
import { useWeb3React } from '@web3-react/core'
import { allLanguages } from 'constants/localisation/languageCodes'
import { LanguageContext } from 'hooks/LanguageContext'
import useTheme from 'hooks/useTheme'
import useGetPriceData from 'hooks/useGetPriceData'
import { injected, bsc, walletconnect } from 'connectors'
import links from './config'

const Menu: React.FC = props => {
  const [muftPriceUsd, setMuftPriceUsd] = useState(0)
  const [mswapPriceUsd, setMswapPriceUsd] = useState(0)
  const { account, activate, deactivate } = useWeb3React()
  const { selectedLanguage, setSelectedLanguage } = useContext(LanguageContext)
  const { isDark, toggleTheme } = useTheme()
  const cakePriceUsd = useGetPriceData()

  const getMuftLivePrice = async () => {
    try {
      const response = await fetch('https://api.coinpaprika.com/v1/coins/muft-manutd-fan-token/markets')
      const data = await response.json()
      return data
    } catch (err) {
      console.log("muftLivePriceError", err)
      throw err
    }
  }
  const getMswapLivePrice = async () => {
    try {
      const response = await fetch('https://api.coinpaprika.com/v1/coins/mswap-muftswap-token/markets')
      const data = await response.json()
      return data
    } catch (err) {
      console.log("mswapLivePriceError", err)
      throw err
    }
  }

  useEffect(() => {
    getMuftLivePrice()
      .then((result) => {
        const muftLivePrice = result[0].quotes.USD.price
        setMuftPriceUsd(muftLivePrice)
      })
      .catch((err) => {
        console.log('call api err', err)
      })
    getMswapLivePrice()
      .then((result) => {
        const muftLivePrice = result[0].quotes.USD.price
        setMswapPriceUsd(muftLivePrice)
      })
      .catch((err) => {
        console.log('call api err', err)
      })
  }, [])

  return (
    <UikitMenu
      links={links}
      priceLink=""  // https://www.coingecko.com/en/coins/goose-finance
      account={account as string}
      login={(connectorId: ConnectorId) => {
        if (connectorId === 'walletconnect') {
          return activate(walletconnect)
        }

        if (connectorId === 'bsc') {
          return activate(bsc)
        }

        return activate(injected)
      }}
      logout={deactivate}
      isDark={isDark}
      toggleTheme={toggleTheme}
      currentLang={selectedLanguage?.code || ''}
      langs={allLanguages}
      setLang={setSelectedLanguage}
      cakePriceUsd={mswapPriceUsd} // mswap
      cake2PriceUsd={muftPriceUsd} // muft
      {...props}
    />
  )
}

export default Menu
