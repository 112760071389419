import { MenuEntry } from '@pancakeswap-libs/uikit'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeUpdatedIcon',
    href: 'https://muftswap.exchange/',
  },
  {
    label: 'Exchange',
    icon: 'TradeIcon',
    initialOpenState: true,
    items: [
      {
        label: 'Swap',
        href: '/swap'
      },
      {
        label: 'Liquidity',
        href: '/liquidity'
      }
    ]
  },
  {
    label: 'Devil`s Farms',
    icon: 'FarmUpdatedIcon',
    href: 'https://muftswap.exchange/farms',
  },
  {
    label: 'Red Pools',
    icon: 'PoolIcon',
    href: 'https://muftswap.exchange/pools',
  },
  {
    label: 'Doom Pit',
    icon: 'DoomPitIcon',
    href: 'https://muftswap.exchange/doompit',
  },
  {
    label: 'Football Predictions',
    icon: 'PredictionIcon',
    href: 'https://muftswap.exchange/prediction',
  },
  {
    label: 'Football Games',
    icon: 'GameIcon',
    href: 'https://muftswap.exchange/game',
  },
  {
    label: 'SNFT Marketplace',
    icon: 'HomeIcon',
    href: 'https://muftswap.exchange/snft',
  },
  {
    label: 'Audits',
    icon: 'LotteryIcon',
    href:
      'https://github.com/solidproof/smart-contract-audits/blob/main/SmartContract_Audit_Solidproof_ManUtdFantoken.pdf',
  },
  {
    label: 'ILO',
    icon: 'MoonIcon',
    href: 'https://ilo.muftswap.exchange/',
  },
  {
    label: 'Listings',
    icon: 'MoreIcon',
    items: [
      {
        label: 'CoinMarketCap',
        href: 'https://coinmarketcap.com/currencies/muftswap/',
      },
      {
        label: 'CoinMooner',
        href: 'https://coinmooner.com/coin/6924/',
      },
      {
        label: 'CoinGecko',
        href: 'https://muftswap.exchange/cg',
      },
      {
        label: 'FTX',
        href: 'https://muftswap.exchange/ftx',
      },
    ],
  },
]

export default config
